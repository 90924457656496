<template>
  <div class="main-wrapper" v-loading="!loaded"
       element-loading-text="正在验证授权"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <keep-alive >
    <router-view v-if="isAuthored&&loaded"/>
    </keep-alive>
  </div>
</template>

<script>

import {isDesensitizationMode, isDesensitizationUuid} from "@/utils/helper";
import {Dialog} from "vant";
import {checkShareCode} from "@/api/api";
import {LocalStorage} from "ttl-localstorage";

export default {
  name: "Always",
  components:{
  },
  props: {
    uuid: String,
    shareCode: String
  },
  data(){
    return {
      loaded: false,
      isAuthored: false
    }
  },
  async mounted() {
    // if(isDesensitizationUuid(this.uuid)&&isDesensitizationUuid(this.$route.query.uuid)){
    //   await this.loadToken();
    //   this.loaded = true;
    //   this.isAuthored=true
    // }else if(navigator.userAgent.toLowerCase().includes("miniprogram")){
    //   this.loaded = true;
    //   this.isAuthored=true
    // }else{
    //   Dialog({ message: '该报告没有权限访问' })
    // }
  },
  methods: {
    async loadToken() {
      const res = await checkShareCode(this.$route.query.uuid, '')
      if (res.msgCode == 1) {
        if (res.data.result) {
          if(res.data.token){
            localStorage.setItem('Share-Authorization',res.data.token)
          }
        } else {
          console.error(res.data.cause);
        }


      } else {
        this.error = "未知错误";
      }
    }
  },
  computed: {
  }
}
</script>

<style lang="stylus" scoped>
.main-wrapper {
  // min-height 100vh
}
</style>
