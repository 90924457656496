import { render, staticRenderFns } from "./Always.vue?vue&type=template&id=388a0796&scoped=true"
import script from "./Always.vue?vue&type=script&lang=js"
export * from "./Always.vue?vue&type=script&lang=js"
import style0 from "./Always.vue?vue&type=style&index=0&id=388a0796&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388a0796",
  null
  
)

export default component.exports